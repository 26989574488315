import { createWrapper } from "next-redux-wrapper";
import { createStore, Store } from "redux";
import { User } from "index";
import reducers from "reducers/index";

export type AppState = {
  auth: {
    currentUser: User;
  };
};

const makeStore = () => createStore(reducers);

export const wrapper = createWrapper<Store>(makeStore, { debug: false });
