// Import Reducers
import { combineReducers } from "redux";
import app from "reducers/appReducer";
import auth from "reducers/authReducer";
import tabs from "reducers/tabsReducer";

// Combine Reducers as Redux store can take only one
const reducers = combineReducers({
  auth,
  app,
  tabs,
});

export default reducers;
