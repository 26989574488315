import { REGISTER_TABS_GROUP, SET_ACTIVE_TAB } from "actions";

type Action = {
  type: string;
  group: string;
  tab: string;
};

type State = {
  [key: string]: string | null;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const tabsReducer = (state: State = {}, action: Action) => {
  if (action) {
    switch (action.type) {
      case REGISTER_TABS_GROUP: {
        if (!state[action.group] && state[action.group] !== null) {
          return { ...state, [action.group]: null };
        }
        break;
      }
      case SET_ACTIVE_TAB: {
        return { ...state, [action.group]: action.tab };
      }
      default: {
        return state;
      }
    }
  }
  return state;
};

export default tabsReducer;
