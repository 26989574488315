export const APP_LOADED = "APP_LOADED";

export const APP_LOADING = "APP_LOADING";

export const TOGGLE_MENU = "TOGGLE_MENU";

export const SET_TOKENS = "SET_TOKENS";

export const LOG_OUT = "LOG_OUT";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SET_CURRENT_URL = "SET_CURRENT_URL";

export const SET_SERVER_TIME_DIFFERENCE = "SET_SERVER_TIME_DIFFERENCE";

export const REGISTER_TABS_GROUP = "REGISTER_TABS_GROUP";

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
