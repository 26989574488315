import { HYDRATE } from "next-redux-wrapper";
import { AnyAction } from "redux";
import { SET_TOKENS, LOG_OUT, SET_CURRENT_USER } from "actions";
import { User } from "index";

interface State {
  token: string;
  refreshToken: string;
  tokenExpiration: number;
  currentUser: User | Record<string, unknown>;
}

const initialState = {
  token: "",
  refreshToken: "",
  tokenExpiration: 0,
  currentUser: {},
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const authReducer = (state: State = initialState, action: AnyAction) => {
  if (action) {
    switch (action.type) {
      case HYDRATE: {
        return {
          ...state,
          ...action.payload.auth,
        };
      }

      case SET_TOKENS: {
        const { token, refreshToken, tokenExpiration } = action.tokens;
        return {
          ...state,
          token,
          refreshToken,
          tokenExpiration,
        };
      }

      case LOG_OUT: {
        return initialState;
      }

      case SET_CURRENT_USER: {
        const { user: currentUser } = action;
        return {
          ...state,
          currentUser: currentUser || initialState.currentUser,
        };
      }

      default: {
        return state;
      }
    }
  }

  return state;
};

export default authReducer;
