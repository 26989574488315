import React from "react";
import { wrapper } from "store";
import "styles/above-the-fold.scss";
import "styles/main.scss";
import { AppProps } from "next/app";
import { Ubuntu } from "next/font/google";
import { Provider } from "react-redux";

const ubuntu = Ubuntu({
  weight: ["300", "400", "500"],
  subsets: ["latin"],
});

class ErrorBoundary extends React.Component<{ children: React.ReactNode }> {
  componentDidCatch(error: Error) {
    window.Rollbar?.error(error);
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

const App: React.FC<AppProps> = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const { pageProps } = props;

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <main className={ubuntu.className}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...pageProps} />
        </main>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
