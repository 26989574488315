import { AnyAction } from "redux";
import {
  APP_LOADED,
  APP_LOADING,
  TOGGLE_MENU,
  SET_CURRENT_URL,
  SET_SERVER_TIME_DIFFERENCE,
} from "actions";

interface State {
  loading: boolean;
  menuOpen: boolean;
  serverTimeDifference: number;
}

const initialState = {
  loading: true,
  menuOpen: false,
  serverTimeDifference: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const appReducer = (state: State = initialState, action: AnyAction) => {
  if (action) {
    switch (action.type) {
      case APP_LOADING: {
        return { ...state, loading: true, menuOpen: false };
      }
      case APP_LOADED: {
        return { ...state, loading: false };
      }
      case TOGGLE_MENU: {
        return { ...state, menuOpen: !state.menuOpen };
      }
      case SET_CURRENT_URL: {
        const url = action.url || "";

        return { ...state, url };
      }
      case SET_SERVER_TIME_DIFFERENCE: {
        return { ...state, serverTimeDifference: action.difference };
      }
      default: {
        return state;
      }
    }
  }
  return state;
};

export default appReducer;
